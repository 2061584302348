/*****************************
	Skill
*****************************/
.skill {
	position: relative;
	width: 100%;
	height: 4px;
	margin: 50px 0 20px 0;
	background-color: $gray-2;
	border-radius: $border-radius;

	.skill-bar {
		position: relative;
		height: 100%;
		border-radius: $border-radius;
		padding: 0px;
		background: $bg-primary-gradient;
		box-shadow: none;
		transition: width .9s ease;

		.skill-title {
			color: $gray-8;
			font-size: 18px;
			font-weight: 500;
			position: absolute;
			margin-left: 0;
			margin-top: -28px;
			text-align: left;

			i {
				margin-right: 8px;
			}

		}

		.progress-number {
			color: $gray-8;
			float: right;
			margin-top: -18px;
			position: absolute;
			right: 14px;
			top: -10px;
			font-size: 15px;
			font-weight: 600;
		}

		.progress-type {
			color: $gray-8;
			float: right;
			margin-top: -18px;
			position: absolute;
			right: 0;
			top: -10px;
			font-size: 15px;
			font-weight: 600;
		}

		&:before {
			content: "";
			position: absolute;
			right: 0;
			background: $primary;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 50%;
			height: 10px;
			width: 10px;
		}

	}

}