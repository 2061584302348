/*****************************
  Client
*****************************/

.our-clients-style-01 {
  padding: 0;
  margin: 0;

  li {
    position: relative;
    display: inline-block;
    list-style-type: none;
    text-align: center;
    width: 33.33%;
    float: left;
    border: 1px solid $border-color;
    border-left: none;
    border-top: none;
    cursor: pointer;
    border-bottom: none;
    padding: 40px 0;

    &:nth-child(3n+0) {
      border-right: none;
    }

    &:nth-child(3n+0) ~ li {
      border-top: 1px solid $border-color;
    }

    img {
      cursor: pointer;
      filter: grayscale(100%);
    }

    &:hover {
      img {
        cursor: pointer;
        filter: grayscale(0%);
      }

    }

  }

}

@media (max-width:767px) {
  .our-clients-style-01 {
    li {
      width: 50%;

      &:nth-child(2n+0) {
        border-right: none;
      }

      &:nth-child(3n+0) {
        border-right: 1px solid $border-color;
        border-top: 1px solid $border-color;
      }

      &:last-child {
        border-right: none;
      }

    }

  }

}

@media (max-width:479px) {
  .our-clients-style-01 {
    li {
      width: 100%;
      border-right: none;
      padding: 30px 0px;

      &:nth-child(2n+0) {
        border-right: none;
        border-top: 1px solid $border-color;
      }

      &:nth-child(3n+0) {
        border-right: none;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-right: none;
        padding-bottom: 0;
      }

    }

  }

}